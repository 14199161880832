import React, { FC, memo, useCallback } from "react";
import { hideCpf } from "utils/utils";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import "./WalletRechargeLayout.css";
import { IOrder } from "modules/order/domain/models/IOrder";
import { TransactionType } from "domains/order/agreggates/payment/transactionType";

export interface WalletRechargePrint {
  walletId: string;
  orderId: string;
  balance: number;
  order: IOrder;
}

interface WalletRechargeLayoutProps {
  walletRecharge: WalletRechargePrint;
  kiosk: Kiosk;
}

export const WalletRechargeLayout: FC<WalletRechargeLayoutProps> = memo(
  ({ kiosk, walletRecharge }) => {
    const getTransactionType = useCallback((type: TransactionType) => {
      switch (type) {
        case TransactionType.credit:
          return "Crédito";
        case TransactionType.debit:
          return "Débito";
        case TransactionType.pix:
          return "Pix";
        default:
          return "";
      }
    }, []);

    return (
      <div id="WalletRechargeLayout">
        <div className="header">
          <h5>{kiosk.localName}</h5>
          <div>
            <span>Pedido:</span>
            <span>{walletRecharge.order.friendlyId}</span>
          </div>
          <div className="divisor" />
          <div>
            <b>Recarga Wallet</b>
            <span>
              <b>{`R$ ${walletRecharge.order.totalValue
                .toFixed(2)
                .replace(".", ",")}`}</b>
            </span>
          </div>
        </div>
        <div className="divisor" />
        <div className="content">
          {walletRecharge.order.customer?.document && (
            <p>{`CPF: ${hideCpf(walletRecharge.order.customer.document)}`}</p>
          )}
          <p>{`Tag: ${(walletRecharge.order.customer?.tag ?? "").replace(
            /([A-Za-z0-9]{2})(?=[A-Za-z0-9])/g,
            `$1-`
          )}`}</p>
          <p>{`Saldo: R$${walletRecharge.balance
            .toFixed(2)
            .replace(".", ",")}`}</p>
          <div className="divisor" />
          <p>{`Terminal: ${kiosk.kioskName}`}</p>
          <p>{`Data: ${new Date(walletRecharge.order.createdAt).toLocaleString(
            "pt-BR"
          )}`}</p>
          {walletRecharge.order.payment?.paymentMethod && (
            <p>{`Pagamento: ${getTransactionType(
              walletRecharge.order.payment.paymentMethod
            )}`}</p>
          )}
          <div className="divisor" />
          <p style={{fontSize: 12}}>{`Ido: ${walletRecharge.order.id}`}</p>
          <p style={{fontSize: 12}}>{`Idw: ${walletRecharge.walletId}`}</p>
        </div>
      </div>
    );
  }
);
