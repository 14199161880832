import React, { FC, useCallback, useEffect, useState } from "react";
import styles from "./Extract.module.scss";
import {
  IWalletExtract,
  TransactionType,
} from "modules/cashless/domain/models/IWallet";
import { hideCpf, toDateAndTime } from "utils/utils";
import { Button } from "@material-ui/core";
import { moneyMask } from "corss-cutting/masks/money";
import { ArrowDropUp, ArrowDropDown, Remove } from "@material-ui/icons";

export interface IExtract {
  extract: IWalletExtract;
  onClickAdd: () => void;
}

const Extract: FC<IExtract> = ({ extract, onClickAdd }) => {
  const [isFullHD, setIsFullHD] = useState(window.innerHeight >= 1920);

  useEffect(() => {
    const handleResize = () => {
      setIsFullHD(window.innerHeight >= 1920);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getTransactionLabel = useCallback((type: TransactionType) => {
    switch (type) {
      case TransactionType.Consumption:
        return "Consumo";
      case TransactionType.Recharge:
        return "Recarga";
      case TransactionType.Transfer:
        return "Transferência";
      case TransactionType.Pending:
        return "Pendente";
      case TransactionType.Refund:
        return "Cancelada";
    }
  }, []);

  return (
    <div id={styles.Extract} style={{ marginTop: isFullHD ? "0" : "-100px" }}>
      <div>
        <p style={{ fontSize: isFullHD ? 35 : 25 }}>{extract.meepUser.name}</p>
        <p style={{ fontSize: isFullHD ? 35 : 25 }}>
          {hideCpf(extract.meepUser.cpf)}
        </p>
      </div>

      <div>
        <div>
          {extract.extractWalletPOSItems.map((it, key) => (
            <div key={key} className={styles.item}>
              <div>
                <span>{toDateAndTime(it.date)}</span>
                <span>
                  {it.orderType === TransactionType.Refund ? (
                    <Remove style={{ color: "#a2a2a2" }} />
                  ) : it.value < 0 ? (
                    <ArrowDropUp
                      style={{ color: it.value < 0 ? "#C00D1E" : "#399C54" }}
                    />
                  ) : (
                    <ArrowDropDown
                      style={{ color: it.value < 0 ? "#C00D1E" : "#399C54" }}
                    />
                  )}
                </span>
              </div>
              <div
                style={{
                  color:
                    it.orderType === TransactionType.Refund
                      ? "#a2a2a2"
                      : it.value < 0
                      ? "#C00D1E"
                      : "#399C54",
                }}
              >
                <span>{getTransactionLabel(it.orderType)}</span>
                <span
                  style={{
                    textDecoration:
                      it.orderType === TransactionType.Refund
                        ? "line-through"
                        : undefined,
                  }}
                >{`${it.value < 0 ? "- " : ""}${moneyMask(
                  it.value.toFixed(2)
                )}`}</span>
              </div>
            </div>
          ))}
        </div>

        <p style={{ fontSize: isFullHD ? 60 : 40 }}>
          Saldo <span>{moneyMask(extract.balance.toFixed(2))}</span>
        </p>

        <Button variant="contained" fullWidth onClick={onClickAdd}>
          Adicionar crédito
        </Button>
      </div>
    </div>
  );
};

export default Extract;
