import { ICardReaderStore } from "modules/cardReader/domain/interfaces/IUseCardReaderStore";
import { IOfflineCardData } from "modules/cardReader/domain/models/IOfflineCardData";
import { create } from "zustand";
import { enableSensor } from "./CardReaderActions";
import { subscribeWithSelector } from "zustand/middleware";
import { ReadType } from "modules/cardReader/domain/models/IReadType";

export const useCardReaderStore = create<ICardReaderStore>() (
  subscribeWithSelector((set) => {
    let isActiveSensor: boolean = false;

    const onReadTag = (tag: string) => {
      if (isActiveSensor) {
        set({ tag });
      }
    };
  
    const onReadError = (readError: string) => {
      set({ readError });
    };
  
    const onOfflineCardData = (offlineCardData: IOfflineCardData) => {
      if (offlineCardData) {
        set({ offlineCardData });
      }
    };
  
    const onRemovedCard = () => {
      set({ cardDetected: false, tag: null });
    };
  
    const onDetectedCard = () => {
      set({ cardDetected: true });
    };
  
    if (!window.setCardValue) {
      window.setCardValue = onReadTag;
    }
    if (!window.setReadError) {
      window.setReadError = onReadError;
    }
    if (!window.onRemovedCard) {
      window.onRemovedCard = onRemovedCard;
    }
    if (!window.onDetectedCard) {
      window.onDetectedCard = onDetectedCard;
    }
    if (!window.setFullCardData) {
      window.setFullCardData = onOfflineCardData;
    }
  
    return {
      tag: null,
      cardDetected: false,
      offlineCardData: null,
      readError: null,
      isActiveSensor,
      readType: null,
  
      enableSensor: async (type: ReadType, cashlessCode?: string) => {
        isActiveSensor = true;
        set(() => ({
          readType: type,
          tag: null,
          cardDetected: false,
          offlineCardData: null,
          readError: null,
        }))
        return enableSensor(cashlessCode);
      },

      disableSensor: () => {
        isActiveSensor = false;
        set(() => ({
          tag: null,
          cardDetected: false,
          offlineCardData: null,
          readError: null,
        }))
      },
  
      clearCardReader: () => {
        set(() => ({
          tag: null,
          cardDetected: false,
          offlineCardData: null,
          readError: null,
        }))
      }
    };
  })
);
