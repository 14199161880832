import React, { useEffect, useState, useCallback } from "react";
import { useKiosk } from "../../../application/contexts/kiosk/KioskContext";
import { Grid, Button, Icon, Drawer, IconButton } from "@material-ui/core";
import { AppConfigurationData } from "../../../Infrastructure/models/AppConfigurationData";
import { useSession } from "../../../application/contexts/session/SessionContext";
import { CefSharpConfigRepository } from "../../../Infrastructure/services/cefSharpService/local/CefSharpConfigRepository";
import { MeepOrderRepository } from "../../../Infrastructure/repositories/api/OrderRepository";
import { useUi } from "../../../application/contexts/ui/UIContext";
import { PedidoPos } from "Infrastructure/repositories/api/PedidoMeep";
import { OrderRepositoryLocalStorage } from "Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage";
import { OrderLog } from "domains/order/agreggates/order/Order";
import { CashlessRepository } from "Infrastructure/repositories/api/CashlessRepository";
import { IWalletRechargeModel } from "modules/payment/domain/models/IWalletTransaction";
import { WalletRepository } from "Infrastructure/repositories/api/WalletRepository";
import { WalletRechargeUseCase } from "modules/cashless/application/useCases/WalletRechargeUseCase";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";

const configRepository = CefSharpConfigRepository();
const orderRepository = MeepOrderRepository();
const pendingRechargeRepository = OrderRepositoryLocalStorage();
const cashlessRepository = CashlessRepository();

export const KioskParameters = () => {
  const { kiosk } = useKiosk();
  const { addLog } = useAppInsights();
  const { sessionIsOpen, closeSession, openSession } = useSession();
  const [configData, setConfigData] = useState<AppConfigurationData | null>(
    null
  );
  const { toast, showLoading, hideLoading } = useUi();
  const [pedidosPendentesLista, setPedidosPendentesLista] = useState<
    PedidoPos[]
  >([]);
  const [pedidosPendentes, setPedidosPendentes] = useState<number>();
  const [selectedPort, setSelectedPort] = useState("");
  const [recargasPendentes, setRecargasPendentes] = useState<OrderLog[]>([]);
  const [pendentRecordConfirmation, setPendentRecordConfirmation] = useState<
    PedidoPos[]
  >([]);
  const [pendentWalletRecharge, setPendentWalletRecharge] = useState<
  IWalletRechargeModel[]
  >([]);
  const [openModalPendente, setOpenModalPendente] = useState(false);
  const [openModalRecargaPendente, setOpenModalRecargaPendente] =
    useState(false);
  const [openModalConfirmacaoPendente, setOpenModalConfirmacaoPendente] =
    useState(false);
  const [openModalWalletRechargePendente, setOpenModalWalletRechargePendente] =
    useState(false);

  useEffect(() => {
    setSelectedPort(localStorage.getItem("@weighning-port") ?? "");
    orderRepository.getStoragePendentOrder().then((response) => {
      setPedidosPendentesLista(response);
      setPedidosPendentes(response.length);
    });

    orderRepository
      .getStoragePendentRecordConfirmationOrder()
      .then((response) => {
        setPendentRecordConfirmation(response);
      });

    orderRepository
      .getPendentWalletRecharge()
      .then((response) => {
        setPendentWalletRecharge(response);
      });

    pendingRechargeRepository.getAllPendingRecharge().then((response) => {
      setRecargasPendentes(response);
    });
  }, []);

  const onClickSyncoOrderById = useCallback(
    async (id: string) => {
      if (kiosk) {
        showLoading();
        try {
          const response = await orderRepository.syncOrdersByIdAsync(
            kiosk?.localId,
            id
          );

          toast(
            response.length +
              " de " +
              pedidosPendentes +
              " foram sincronizados",
            "success"
          );

          setPedidosPendentesLista((prev) =>
            prev.filter((item) => item.pedidoPOSId !== id)
          );
          setPedidosPendentes((prev) => (prev ?? 1) - 1);
        } catch (error) {
          toast("Não foi possivel sincronizar", "error");
        } finally {
          hideLoading();
        }
      }
    },
    [kiosk, showLoading, toast, pedidosPendentes, hideLoading]
  );

  const onClickConfirmOrderById = useCallback(
    async (id: string) => {
      if (kiosk) {
        showLoading();
        try {
          const response = await cashlessRepository.confirmPendingRecord(
            [id]
          );

          toast(
            response.ordersId.length +
              " de " +
              pendentRecordConfirmation.length +
              " foram confirmados",
            "success"
          );

        } catch (error) {
          toast("Não foi possivel confirmar", "error");
        } finally {
          hideLoading();
        }
      }
    },
    [kiosk, showLoading, toast, pendentRecordConfirmation.length, hideLoading]
  );

  const onClickConfirmWalletRecharge = useCallback(
    async (walletRecharge: IWalletRechargeModel) => {
      if (kiosk) {
        showLoading();
        try {
          const walletService = WalletRepository();
          const walletTransaction = await WalletRechargeUseCase(
            walletService,
            walletRecharge
          );

          const newList = await orderRepository.removeWalletPendingRechargeFromStorageQueueAsync(walletRecharge.orderId)
          setPendentWalletRecharge(newList);
          const orderLog = {
            id: walletRecharge.orderId, 
            walletRechargeResult: walletTransaction, 
            isSynchronized: true
          }

          addLog("Wallet", {message: "Recarga confirmada", orderLog})

          toast("Recarga confirmada", "success");

          setOpenModalWalletRechargePendente(false);

        } catch (error) {
          addLog("Wallet", {
            orderLog: {
              message: "Falha ao confirmar recarga", 
              id: walletRecharge.orderId
            }, 
          })
          toast("Não foi possivel confirmar", "error");
        } finally {
          hideLoading();
        }
      }
    },
    [kiosk, showLoading, addLog, toast, hideLoading]
  );

  const onClickSyncoOrder = useCallback(() => {
    if (kiosk) {
      orderRepository.syncOrdersAsync(kiosk?.localId).then((response) => {
        toast(
          response.length + " de " + pedidosPendentes + " foram sincronizados",
          "success"
        );
      });

      orderRepository.getStoragePendentOrder().then((response) => {
        setPedidosPendentesLista(response);
        setPedidosPendentes(response.length);
      });
    }
  }, [kiosk, toast, pedidosPendentes]);

  const onClickSyncOneOrder = useCallback(() => {
    if (kiosk) {
      orderRepository.syncLastOrdersAsync(kiosk?.localId).then((response) => {
        toast(
          response.length + " de " + pedidosPendentes + " foram sincronizados",
          "success"
        );
      });
      orderRepository.getStoragePendentOrder().then((response) => {
        setPedidosPendentesLista(response);
        setPedidosPendentes(response.length);
      });
    }
  }, [kiosk, toast, pedidosPendentes]);

  const obterConfiguracoes = useCallback(async () => {
    setConfigData(await configRepository.getConfigAsync());
  }, []);

  useEffect(() => {
    obterConfiguracoes();
  }, [obterConfiguracoes]);

  const selectComWegining = useCallback((value: string) => {
    localStorage.setItem("@weighning-port", value);
    setSelectedPort(value);
  }, []);

  const itemView = useCallback((label: string, value?: string) => {
    return (
      <Grid item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <h4>{label}:</h4> {value}
        </div>
      </Grid>
    );
  },[]);

  return (
    <Grid spacing={2} container>
      <Grid sm={12} item>
        <Grid item></Grid>
        {itemView("LocalId", kiosk?.localId)}
        {itemView("Local Nome", kiosk?.localName)}
        {itemView("Equipamento ID", configData?.equipamentId)}
        {itemView("MacAddress", configData?.macAddress)}
      </Grid>
      {!!pedidosPendentes ? (
        <Grid
          spacing={1}
          sm={12}
          style={{
            color: "#fff",
            borderRadius: 16,
            backgroundColor: "#f80",
            marginBottom: 16,
          }}
          item
        >
          <>
            <Grid onClick={() => setOpenModalPendente(true)} sm={12} item>
              Existem {pedidosPendentes} pedidos pendentes.
              <IconButton onClick={() => setOpenModalPendente(true)}>
                <Icon>infor</Icon>
              </IconButton>
            </Grid>
            <Grid sm={12} item>
              <Button
                disabled={!pedidosPendentes}
                color={"primary"}
                variant={"contained"}
                style={{ backgroundColor: "#1dc" }}
                onClick={onClickSyncoOrder}
              >
                Sincronizar {pedidosPendentes} Pedidos pendentes
              </Button>
            </Grid>
            <Grid sm={12} item>
              <Button
                disabled={!pedidosPendentes}
                color={"primary"}
                variant={"contained"}
                style={{ backgroundColor: "#1ac" }}
                onClick={onClickSyncOneOrder}
              >
                Ultimo Pedido pendente
              </Button>
            </Grid>
          </>
        </Grid>
      ) : (
        <Grid
          sm={12}
          style={{
            color: "#fff",
            borderRadius: 16,
            backgroundColor: "#285",
            marginBottom: 16,
          }}
          item
        >
          <Grid sm={12} item>
            Nenhum pedido pendente.
          </Grid>
        </Grid>
      )}
      {recargasPendentes.length > 0 ? (
        <Grid
          spacing={1}
          sm={12}
          style={{
            color: "#fff",
            borderRadius: 16,
            backgroundColor: "#f80",
            marginBottom: 16,
          }}
          item
        >
          <>
            <Grid
              onClick={() => setOpenModalRecargaPendente(true)}
              sm={12}
              item
            >
              Existem {recargasPendentes.length} recargas pendentes.
              <IconButton onClick={() => setOpenModalRecargaPendente(true)}>
                <Icon>infor</Icon>
              </IconButton>
            </Grid>
          </>
        </Grid>
      ) : (
        <Grid
          sm={12}
          style={{
            color: "#fff",
            borderRadius: 16,
            backgroundColor: "#285",
            marginBottom: 16,
          }}
          item
        >
          <Grid sm={12} item>
            Nenhuma recarga pendente.
          </Grid>
        </Grid>
      )}
      {pendentRecordConfirmation.length > 0 ? (
        <Grid
          spacing={1}
          sm={12}
          style={{
            color: "#fff",
            borderRadius: 16,
            backgroundColor: "#f80",
            marginBottom: 16,
          }}
          item
        >
          <>
            <Grid
              onClick={() => setOpenModalConfirmacaoPendente(true)}
              sm={12}
              item
            >
              Existem confirmações de gravação
              pendentes.
              <IconButton onClick={() => setOpenModalConfirmacaoPendente(true)}>
                <Icon>infor</Icon>
              </IconButton>
            </Grid>
          </>
        </Grid>
      ) : (
        <Grid
          sm={12}
          style={{
            color: "#fff",
            borderRadius: 16,
            backgroundColor: "#285",
            marginBottom: 16,
          }}
          item
        >
          <Grid sm={12} item>
            Nenhuma gravação pendente.
          </Grid>
        </Grid>
      )}
      {pendentWalletRecharge.length > 0 ? (
        <Grid
          spacing={1}
          sm={12}
          style={{
            color: "#fff",
            borderRadius: 16,
            backgroundColor: "#f80",
            marginBottom: 16,
          }}
          item
        >
          <>
            <Grid
              onClick={() => setOpenModalWalletRechargePendente(true)}
              sm={12}
              item
            >
              Existem recargas Wallet
              pendentes.
              <IconButton onClick={() => setOpenModalWalletRechargePendente(true)}>
                <Icon>infor</Icon>
              </IconButton>
            </Grid>
          </>
        </Grid>
      ) : (
        <Grid
          sm={12}
          style={{
            color: "#fff",
            borderRadius: 16,
            backgroundColor: "#285",
            marginBottom: 16,
          }}
          item
        >
          <Grid sm={12} item>
            Nenhuma recarga Wallet pendente.
          </Grid>
        </Grid>
      )}

      <Grid
        sm={12}
        style={{
          color: "#fff",
          borderRadius: 16,
          backgroundColor: "#1ac",
          marginBottom: 16,
        }}
        item
      >
        <Grid sm={12} item>
          Porta serial balança:
          <select
            style={{ fontSize: 20 }}
            value={selectedPort}
            onChange={(ev) => selectComWegining(ev.target.value)}
          >
            <option value={"COM1"}>COM1</option>
            <option value={"COM2"}>COM2</option>
            <option value={"COM3"}>COM3</option>
            <option value={"COM4"}>COM4</option>
            <option value={"COM5"}>COM5</option>
            <option value={"COM6"}>COM6</option>
            <option value={"COM7"}>COM7</option>
            <option value={"COM8"}>COM8</option>
            <option value={"COM9"}>COM9</option>
            <option value={"COM10"}>COM10</option>
            <option value={"COM11"}>COM11</option>
            <option value={"COM12"}>COM12</option>
            <option value={"COM13"}>COM13</option>
            <option value={"COM14"}>COM14</option>
            <option value={"COM15"}>COM15</option>
            <option value={"COM16"}>COM16</option>
            <option value={"COM17"}>COM17</option>
            <option value={"COM19"}>COM19</option>
          </select>
        </Grid>
      </Grid>
      <Grid
        style={{
          borderColor: "#a00",
          border: 1,
          borderStyle: "solid",
          borderRadius: 5,
          padding: 8,
        }}
        container
      >
        <Grid sm={4} spacing={2} item>
          Status: {sessionIsOpen ? "Aberto" : "Fechado"}
        </Grid>
        <Grid sm={4} item>
          <Button
            disabled={sessionIsOpen}
            fullWidth
            style={{
              opacity: sessionIsOpen ? 0.2 : 1,
              backgroundColor: "#060",
              color: "#fff",
            }}
            onClick={() => openSession()}
            variant="contained"
          >
            Abrir Caixa
          </Button>
        </Grid>
        <Grid sm={4} item>
          <Button
            disabled={!sessionIsOpen}
            fullWidth
            style={{
              opacity: sessionIsOpen ? 1 : 0.2,
              backgroundColor: "#822",
              color: "#fff",
            }}
            onClick={() => closeSession()}
            variant="contained"
          >
            Fechar Caixa
          </Button>
        </Grid>
      </Grid>
      <Grid sm={12} item>
        <Button
          style={{ backgroundColor: "#222", color: "#fff" }}
          onClick={() => {
            window.close();
          }}
          variant="contained"
        >
          <Icon>close</Icon>Fechar Aplicacao
        </Button>
      </Grid>
      <Drawer
        open={openModalPendente}
        onClose={() => setOpenModalPendente(false)}
      >
        <div style={{ width: "90vw", maxHeight: "80vh", padding: 16 }}>
          <h2>Pedidos pendentes</h2>
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            {pedidosPendentesLista.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: 16,
                  border: "1px solid #555",
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: 16,
                  marginBottom: 8,
                }}
              >
                <div>{item.codigoPedido}</div>
                <div>
                  {item.dataPedido &&
                    new Date(item.dataPedido).toLocaleString()}
                </div>
                <div>R${item.valorTotal.toFixed(2)}</div>
                {item.pedidoPOSId && (
                  <IconButton
                    onClick={() =>
                      onClickSyncoOrderById(item.pedidoPOSId ?? "")
                    }
                  >
                    <Icon>sync</Icon>
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        </div>
      </Drawer>
      <Drawer
        open={openModalRecargaPendente}
        onClose={() => setOpenModalRecargaPendente(false)}
      >
        <div style={{ width: "90vw", maxHeight: "80vh", padding: 16 }}>
          <h2>Gravações pendentes</h2>
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            {recargasPendentes.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: 16,
                  border: "1px solid #555",
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: 16,
                  marginBottom: 8,
                }}
              >
                <div>{item.friendlyId}</div>
                <div>{item.customer?.tag}</div>
                <div>
                  {item.createdAt && new Date(item.createdAt).toLocaleString()}
                </div>
                <div>R${item.totalValue.toFixed(2)}</div>
              </div>
            ))}
          </div>
          <p style={{ padding: "0 16px", textAlign: "center" }}>
            Vá para a tela inicial da recarga e aproxime os cartões com recargas
            pendentes para concluir a gravação do novo saldo, ou use uma POS.
          </p>
        </div>
      </Drawer>
      <Drawer
        open={openModalConfirmacaoPendente}
        onClose={() => setOpenModalConfirmacaoPendente(false)}
      >
        <div style={{ width: "90vw", maxHeight: "80vh", padding: 16 }}>
          <h2>Confirmações de gravação pendentes</h2>
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            {pendentRecordConfirmation.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: 16,
                  border: "1px solid #555",
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: 16,
                  marginBottom: 8,
                }}
              >
                <div>{item.codigoPedido}</div>
                <div>
                  {item.dataPedido &&
                    new Date(item.dataPedido).toLocaleString()}
                </div>
                <div>R${item.valorTotal.toFixed(2)}</div>
                {item.pedidoPOSId && (
                  <IconButton
                    onClick={() =>
                      onClickConfirmOrderById(item.pedidoPOSId ?? "")
                    }
                  >
                    <Icon>sync</Icon>
                  </IconButton>
                )}
              </div>
            ))}
          </div>
        </div>
      </Drawer>
      <Drawer
        open={openModalWalletRechargePendente}
        onClose={() => setOpenModalWalletRechargePendente(false)}
      >
        <div style={{ width: "90vw", maxHeight: "80vh", padding: 16 }}>
          <h2>Confirmações de recargas Wallet pendentes</h2>
          <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
            {pendentWalletRecharge.map((item, index) => (
              <div
                key={index}
                style={{
                  padding: 16,
                  border: "1px solid #555",
                  display: "flex",
                  justifyContent: "space-between",
                  borderRadius: 16,
                  marginBottom: 8,
                }}
              >
                <div>{item.orderId}</div>
                <div>
                  {item?.date &&
                    new Date(item.date).toLocaleString()}
                </div>
                <div>R${item.value.toFixed(2)}</div>
                  <IconButton
                    onClick={() =>
                      onClickConfirmWalletRecharge(item)
                    }
                  >
                    <Icon>sync</Icon>
                  </IconButton>
              </div>
            ))}
          </div>
        </div>
      </Drawer>
    </Grid>
  );
};
