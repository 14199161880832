import { ICustomer } from "./ICustomer";
import { Payment } from "./Payment";
import { PixApprovedTransaction } from "../../../payment/domain/models/PixApprovedTransaction";
import { IWalletRechargeResponse, IWalletSyncTransaction } from "modules/payment/domain/models/IWalletTransaction";

export interface IOrder {
  id: string;
  cartDate: string;
  orderItems: IOrderItem[];
  totalWithoutDiscount?: number;
  totalValue: number;
  payment?: Payment;
  friendlyId: string;
  createdAt: string;
  passwordPanelCode?: string;
  isDataRecorded?: boolean | null;
  customer?: ICustomer;
  observation?: string;
  paymentPix?: PixApprovedTransaction;
  paymentWallet?: IWalletSyncTransaction;
  groupDiscountOrder?: boolean;
  isOfflineRecharge?: boolean;
  isOfflineRechargeRecordConfirmed?: boolean;
  walletRechargeResult?: IWalletRechargeResponse;
}

export interface IOrderItem {
  id: string;
  name: string;
  category: string;
  price: number;
  description: string | null;
  productPrice: number;
  productId: string;
  productType: ProdutoTipo;
  noServiceFee?: boolean;
  hideCatalog: boolean;
  isWeight?: boolean;
  discount?: number;
  isBarcodeUnity?: boolean;
  realPrice: number;
  totalPrice: number;
  quantity: number;
  imageUrl: string;
  barcode: string | null;
  orderComposition: IOrderComposition[] | null;
  compositions: IComposition[] | null;
  printerName: string | null;
}

export interface IComposition {
  id: string;
  description: string;
  min: number;
  max: number;
  compositionItems: ICompositionItem[];
}

export interface ICompositionItem {
  id: string; // produtoId
  compositionId: string;
  produtoId: string; //TODO: remover produtoID
  productId: string;
  index: number;
  description: string;
  price: number;
  imageUrl: string;
  min: number;
  max: number;
}

export enum ProdutoTipo {
  Produto = 1,
  Recarga = 2,
  Variavel = 3,
  Promocao = 4,

  AtivacaoCashless = 5,
  DesativacaoCashless = 6,

  AtivacaoCashlessPosPago = 7,
  DesativacaoCashlessPosPago = 8,

  AtivacaoCashlessComanda = 9,
  DesativacaoCashlessComanda = 10,
  TaxaServico = 11,
  Kilo = 12,
  Ajuste = 13,
  TaxaEntrega = 14,
  Agendamento = 50,
}

export interface IOrderComposition {
  id: string;
  compositionId: string;
  description: string;
  imageUrl?: string;
  totalPrice: number;
  orderCompositionItems: IOrderCompositionItem[];
}

export interface IOrderCompositionItem {
  id: string;
  compositionId: string;
  produtoId: string;
  productId?: string;
  description: string;
  price: number;
  quantityFree: number;
  quantityFreeSelected: number;
  compositionItemId: string;
  quantity: number;
  itemType: number;
}
