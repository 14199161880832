import { useCardReader } from "application/contexts/cardReader/CardReaderContext";
import { useUi } from "application/contexts/ui/UIContext";
import { Reading } from "areas/cashless/offlineRecharge/components/reading/Reading";
import { BotaoVoltar } from "components/botaoVoltar/BotaoVoltar";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DocumentsRepository } from "./documentsRepository/DocumentsRepository";
import styles from "./RequestDocument.module.scss";

const logo = require("../../assets/images/logo-meep-novo.png");
const totemGif = require("../../assets/images/totem.gif");

const documentsRepository = DocumentsRepository();

export interface IRequestDocumentProps {
  //propertys
}
const RequestDocument: FC<IRequestDocumentProps> = ({}) => {
  const { offlineCardData, clearCardData, enableSensor, disableSensor, readErrorMessage, cardDetected } = useCardReader();
  const navigation = useHistory();
  const { toastFullscreen, toast } = useUi();

  const [requestTag, setRequestTag] = useState<"reading" | "writing" | null>(null);
  const [entryState, setEntryState] = useState<"allow" | "notAllow" | "error" | null>(null);

  const onClickVoltar = useCallback(() => {
    clearCardData();
    navigation.replace("/");
  }, [clearCardData, navigation]);

  useEffect(() => {
    if (cardDetected) {
      setRequestTag("reading");
      setTimeout(() => {
        setRequestTag(null);
      }, 3000);
    }
  }, [cardDetected]);

  useEffect(() => {
    enableSensor();
    return () => {
      disableSensor();
    };
  }, [enableSensor, disableSensor]);

  useEffect(() => {
    const verifyDocument = async (document: string) => {
      try {
        console.log("UAI");
        const result = await documentsRepository.add(document);
        if (result) {
          console.log("novo cliente");
          setEntryState("allow");
        } else {
          console.log("Usuário já pegou brinde");
          setEntryState("notAllow");
        }
      } catch (error) {
        console.log("Erro", error);
        setEntryState("error");
      }
    };
    if (offlineCardData) {
      verifyDocument(offlineCardData.Document);
    }
  }, [navigation, offlineCardData, toast]);

  useEffect(() => {
    if (requestTag === null && entryState !== null) {
      switch (entryState) {
        case "allow":
          navigation.replace("/catalog/store/" + 0);
          return;
        case "notAllow":
          const message = (
            <div className="offline-recharce-success-message">
              <h3 className="offline-recharce-success-message-h3">
                <b>Você já pegou seu brinde hoje</b>
                <p>
                  <b>Te esperamos aqui amanha!</b>
                </p>
              </h3>
            </div>
          );
          toastFullscreen(message, "warning");
          navigation.replace("/");
          return;
        case "error":
          toast("Falha ao verificar dados", "warning");
      }
    }
  }, [entryState, navigation, requestTag, toast, toastFullscreen]);

  useEffect(() => {
    if (readErrorMessage) {
      const splited = readErrorMessage.split(":");
      if (splited[0] === "Err intr") {
        const message = (
          <div className="offline-recharce-success-message">
            <h3 className="offline-recharce-success-message-h3">
              <b>{splited[1]}</b>
            </h3>
            {/* <p style={{margin: '16px'}}>Procure um caixa móvel para ativar!</p> */}
            <div className="offline-recharce-success-message-div1">
              <p>Procure um caixa móvel</p>
              <p>
                <b>para ativar seu cartão!</b>
              </p>
            </div>
          </div>
        );
        setTimeout(() => {
          toastFullscreen(message, "warning", {
            icon: "error_outline",
            iconSize: 200,
            time: 3000,
          });
          navigation.replace("/");
        }, 1000);
        clearCardData();
      } else {
        // !showLoading && toast("Falha ao ler TAG, tente novamente", "warning");
      }
    }
  }, [readErrorMessage, toastFullscreen, clearCardData, toast, navigation]);

  return (
    <div id={styles.RequestDocument}>
      <div
        id="offline-recharge"
        style={{
          backgroundColor: "#4D13B7",
        }}
      >
        <div className="offline-recharge-header">
          <div
            className="offline-recharge-header-idle-counter"
            style={{
              color: "#fff",
            }}
          >
            {/* {idleCounter && <>{idleCounter}</>} */}
          </div>
          <img
            width={250}
            src={logo}
            alt="logo"
            style={{
              filter: "invert(100%) sepia(40%) saturate(0) hue-rotate(222deg) brightness(119%) contrast(115%)",
            }}
          />
        </div>

        <div>
          <div className="offline-recharge-totem-message-read">
            <p>
              <b>Para começar,</b>
            </p>
            <p>
              aproxime seu <b>cartão</b>
            </p>
          </div>
          <div>
            <img className="offline-recharge-totem" src={totemGif} alt="totem" />
          </div>
          <div className="offline-recharge-totem-message-read-b fa-blink">
            <p>
              <b>Não retire,</b>
            </p>
            <p>
              até a <b>conclusão!</b>
            </p>
          </div>
        </div>
        <div className="offline-recharge-empty" />

        <BotaoVoltar onClick={onClickVoltar} />
        <Reading active={requestTag} />
      </div>
    </div>
  );
};
export default RequestDocument;