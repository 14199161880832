export type Tour = {
    id: string,
    name: string,
    imageUrl: string,
    description?: string,
    vacancies?: number,
    duration?: number,
    startTime?: string,
    place?: string,
}

export enum TransactionType {
    credit = 1,
    debit = 2,
    money = 3,
    cashless = 4,
    misto = 6,
    voucher = 14,
    pix = 18,
    wallet = 22
}

export interface ReserveTour {
    id?:string,
    date: string,
    time: string,
    customers: Customers[],
    totalPrice: number
}

export interface Customers {
    id:string,
    name: string,
    cpf: string,
    birthday: string;
    phone: string;
    note: string;
    rg:string;
    price: { description: string, price: number, minAge?: number,maxAge?: number }
}