import { paymentExtractToPayAccountRequest } from "domains/cashless/aggregates/IPaymentAccount";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { Payment } from "domains/order/agreggates/payment/payment";
import {
  ICardCashless,
  ICardGroupDiscounts,
  IConfirmRecordRequest,
  IConfirmRecordResponse,
  IGetPendingRecords,
  IPendingRecordsResposne,
} from "../../../domains/cashless/aggregates/ICardCashless";
import { IExtract } from "../../../domains/cashless/aggregates/IExtract";
import { IOrderPad } from "../../../domains/cashless/aggregates/IOrderPad";
import { IHttpRepository } from "../../interfaces/IHttpRepository";
import { ExtractRepository } from "./ExtractRepository";
import { HttpRepository } from "./_HttpRepository";
import { DadosConsumoPOS } from "./PedidoMeep";
import { ICashlessRepository } from "domains/cashless/interfaces/ICashlessRepository";

const extractService = ExtractRepository();

export const CashlessRepository = (): ICashlessRepository => {
  const httpRepository: IHttpRepository = HttpRepository();

  const getCardData = async (
    id: string,
    tag: string
  ): Promise<ICardCashless> => {
    return await httpRepository.getAsync<ICardCashless>(`/dispositivo/cartaocashless/${id}/${tag}`);
  };

  const getSchoolExtractByNickname = async (nickname: string): Promise<any> => {
    return await httpRepository.getAsync<any>(`/dispositivo/cartaocashless/apelido/${nickname}`);
  };

  const getGroupData = async (
    localClienteId: string,
    historicoAssociacaoId: string,
    tag: string
  ): Promise<ICardGroupDiscounts[]> => {
    return httpRepository.postAsync<any, ICardGroupDiscounts[]>(`/dispositivo/cartaocashless/buscarDescontosPorCartao`, { localClienteId, historicoAssociacaoId, tag });
  };

  /**
   * Consumacao minima IDs estabelecimentos
   * @param historicoAssociacaoId
   * @param localId
   * @param cardId
   * @returns
   */
  const getExtractData = async (
    historicoAssociacaoId: string,
    localId: string,
    cardId: string,
    isPosPaidAndMinConsuption: boolean
  ): Promise<IExtract> => {
    if (isPosPaidAndMinConsuption) {
      const responseParsed = await extractService.getOldExtract(
        cardId,
        localId
      );
      return responseParsed;
    } else {
      const responseParsed = await extractService.getSummaryExtract(
        historicoAssociacaoId,
        localId
      );
      return responseParsed;
    }
  };

  const getOrdPads = async (localId: string): Promise<IOrderPad[]> => {
    return await httpRepository.getAsync<IOrderPad[]>(`/dispositivo/${localId}/comandas`);
  };

  const payAccount = async (params: {
    kiosk: Kiosk;
    localId: string;
    orderId: string;
    paymentId: string;
    serviceFeeEnabled: boolean;
    cardData: ICardCashless;
    extract: IExtract;
    orderPad: IOrderPad;
    payment: Payment;
  }): Promise<any> => {
    return await httpRepository.postAsync("/CommandEletronic/PayAccount",paymentExtractToPayAccountRequest({ ...params }));
  };

  const doConsumption = async (
    consumptionOrder: DadosConsumoPOS
  ): Promise<{ orderId: string }> => {
    return await httpRepository.postAsync("/CommandEletronic/ConsumeOrder", consumptionOrder);
  };

  const getPendingRecharge = async (
    card: IGetPendingRecords
  ): Promise<IPendingRecordsResposne> => {
    // const result = await httpRepository.postAsync<IGetPendingRecords, IPendingRecordsResposne>("https://162afb2aab7d.ngrok.app/Promptus.Meep.Server/api/PreCashlessOffline/GetPendingRecharges", card);
    const result = await httpRepository.postAsync<IGetPendingRecords, IPendingRecordsResposne>("/PreCashlessOffline/GetPendingRecharges", card);
    return result;
  };

  const confirmPendingRecord = async (
    orderId: string[]
  ): Promise<IConfirmRecordResponse> => {
    const payload: IConfirmRecordRequest = {
      ordersId: orderId,
    };
    // const result = await httpRepository.postAsync<IConfirmRecordRequest, IConfirmRecordResponse>("https://c763fdaa6b8e.ngrok.app/Promptus.Meep.Server/api/PreCashlessOffline/ConfirmePendingRecharges", payload);
    const result = await httpRepository.postAsync<IConfirmRecordRequest, IConfirmRecordResponse>("/PreCashlessOffline/ConfirmePendingRecharges", payload);
    return result;
  };

  return {
    getCardData,
    getExtractData,
    getOrdPads,
    payAccount,
    doConsumption,
    getSchoolExtractByNickname,
    getGroupData,
    getPendingRecharge,
    confirmPendingRecord,
  };
};
