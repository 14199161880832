import { MeepOrderRepository } from "Infrastructure/repositories/api/OrderRepository";
import { OrderRepositoryLocalStorage } from "Infrastructure/repositories/localStorage/OrderRepositoryLocalStorage";
import { useKiosk } from "application/contexts/kiosk/KioskContext";
import { usePrinter } from "application/contexts/printer/PrinterContext";
import { useUi } from "application/contexts/ui/UIContext";
import { ErrorCodeType, IError } from "application/models/IError";
import { IModalNotaFiscalRef } from "areas/catalog/components/modalNotaFiscal/ModalNotaFiscal";
import { IActionModalHandler } from "components/actionModal/ActionModal";
import { IPrinterOrderRef } from "components/layoutsPrinter/printerOrder/PrinterOrder";
import { Kiosk } from "domains/kiosk/aggregates/Kiosk";
import { NfceService } from "domains/order/services/NfceService";
import { PrintService } from "domains/order/services/printService";
import { ICartContext } from "modules/cart/domain/interfaces/IUseCaseCart";
import { useCartStore } from "modules/cart/infra/store/CartStore";
import {
  IPaymentRef,
  IPaymentResult,
} from "modules/payment/presentation/PaymentProvider";
import { useCallback, useImperativeHandle, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetError } from "utils/GetError";
import { EmitNfceUseCase } from "../application/useCases/EmitNfceUseCase";
import { PrintOrderUseCase } from "../application/useCases/PrintOrderUseCase";
import {
  ISyncOrderResult,
  SyncOrderUseCase,
} from "../application/useCases/SyncOrderUseCase";
import { IEmitedNfce } from "../domain/models/INfce";
import { IOrder } from "../domain/models/IOrder";
import { IOrderLog } from "../domain/models/IOrderLog";
import { IPedidoPosDTO, StatusPedido } from "../domain/models/IPedidoPosDTO";
import {
  IFinancialTransactionModel,
  Payment,
  TransactionType,
} from "../domain/models/Payment";
import { IOrderProviderRef } from "./OrderProvider";
import { ICustomerIdentificationHandler } from "./customerIdentification/CustomerIdentification";
import { useSession } from "application/contexts/session/SessionContext";
import { IPasswordPainel } from "Infrastructure/repositories/api/PasswordPainel";
import { GetPasswordUseCase } from "../application/useCases/GetPasswordUseCase";
import { useCardReader } from "application/contexts/cardReader/CardReaderContext";
import {
  PixApprovedTransaction,
  PixPendingTransaction,
} from "modules/payment/domain/models/PixApprovedTransaction";
import { useAppInsights } from "Infrastructure/repositories/appInsights/AppInsights";

const locaStorageOrderRepository = OrderRepositoryLocalStorage();
const nfceService = NfceService();
const printService = PrintService();

const UseOrderProvider = (
  orderProviderRef: React.ForwardedRef<IOrderProviderRef>,
  printReceiptRef: React.RefObject<IActionModalHandler>,
  printNfceRef: React.RefObject<IActionModalHandler>,
  customerRef: React.RefObject<ICustomerIdentificationHandler>
) => {
  const paymentProvider = useRef<IPaymentRef>(null);
  const nfcePrintRef = useRef<IModalNotaFiscalRef>(null);
  const countPaymentVoucher = useRef(0);
  const printerOrderComponentRef = useRef<IPrinterOrderRef>(null);
  const paymentReceipt = useRef<Payment>();
  const _order = useRef<IOrder>();

  const [clearCart] = useCartStore((state: ICartContext) => [state.clearCart]);
  const { sessaoIdPos } = useSession();
  const { toastFullscreen, toast, showLoading, hideLoading } = useUi();
  const { kiosk } = useKiosk();
  const { printOrder, printInvoice, printRemote, printWalletReceipt } =
    usePrinter();
  const { replace } = useHistory();
  const { cardData, isSchoolData } = useCardReader();
  const { addLog } = useAppInsights();
  const orderRepository = MeepOrderRepository(addLog);

  const [rechargePayment, setRechargePayment] = useState(false);

  const addOrderLog = useCallback(
    async (
      order: IOrderLog | null,
      message: string,
      pedidoPOS?: IPedidoPosDTO,
      payment?: Payment,
      nfce?: IEmitedNfce[]
    ) => {
      try {
        if (order) {
          let orderLog = { ...order, payment, pedidoPOS, nfce };
          console.log({ orderLog, message });
          if (message.includes("Pagamento realizado")) {
            const log = {
              id: order.id,
              cartDate: order.cartDate,
              totalWithoutDiscount: order.totalWithoutDiscount,
              totalValue: order.totalValue,
              payment: order.payment,
              friendlyId: order.friendlyId,
              createdAt: order.createdAt,
              passwordPanelCode: order.passwordPanelCode,
              customer: order.customer,
              paymentPix: order.paymentPix,
              groupDiscountOrder: order.groupDiscountOrder,
            };
            addLog("order", { message, log });
          } else {
            addLog("order", { message, orderLog });
          }
          await locaStorageOrderRepository.add(orderLog, message);
        }
      } catch (error) {
        GetError(
          error,
          "addOrderLog",
          "OrderProvider",
          "Falha ao adicionar log",
          ErrorCodeType.GENERAL
        );
      }
    },
    [addLog]
  );

  const syncOrder = useCallback(
    async (order: IOrder, transactionType: TransactionType, kiosk: Kiosk) => {
      try {
        showLoading();
        const syncResult = await SyncOrderUseCase(
          orderRepository,
          order,
          transactionType,
          kiosk
        );
        addOrderLog(order, "A sincronizar", syncResult.pedidoPOS);
        orderRepository.syncOrdersAsync(kiosk.localId);
        return syncResult;
      } catch (error) {
        GetError(
          error,
          "syncOrder",
          "UseOrderProvider",
          "Falha ao sincronizar pedido",
          ErrorCodeType.GENERAL
        );
      }
    },
    [addOrderLog, orderRepository, showLoading]
  );

  const printPaymentReceipt = useCallback(async () => {
    countPaymentVoucher.current = countPaymentVoucher.current + 1;
    try {
      if (paymentReceipt.current) {
        if (countPaymentVoucher.current <= 1) {
          if (
            paymentReceipt.current.paymentMethod === TransactionType.wallet &&
            paymentReceipt.current.walletReceipt
          ) {
            await printWalletReceipt({
              ...paymentReceipt.current.walletReceipt,
              orderDate: _order.current?.createdAt ?? "",
              friendlyId: _order.current?.friendlyId ?? "",
            });
          } else {
            await printService.printString(
              paymentReceipt.current.comprovanteTEF
            );
            countPaymentVoucher.current = 0;
          }
        }
      }
    } catch (error) {
      GetError(
        error,
        "printPaymentReceipt",
        "UseOrderProvider",
        "Falha ao imprimir comprovante",
        ErrorCodeType.GENERAL
      );
    }
  }, [printWalletReceipt]);

  const handleReceiptPrintModal = useCallback(
    async (kiosk: Kiosk, transactionType: TransactionType) => {
      if (kiosk.printReceipt) {
        if (transactionType !== TransactionType.pospaid) {
          try {
            hideLoading();
            await printReceiptRef.current?.open();
            printPaymentReceipt();
          } catch (error) {
            GetError(
              error,
              "printTicket",
              "UseOrderProvider",
              `${(error as any)?.message ?? "Tempo expirado"}`,
              ErrorCodeType.GENERAL
            );
          }
        }
      }
    },
    [hideLoading, printPaymentReceipt, printReceiptRef]
  );

  const emitNfce = useCallback(
    async (order: IOrder) => {
      try {
        addOrderLog(order, "Emitir NF");
        const nfce = await EmitNfceUseCase(nfceService, order);
        if (!!nfce.length) {
          addOrderLog(order, "NF Emitida", undefined, undefined, nfce);
          return nfce;
        } else {
          addOrderLog(order, "Falha ao emitir NF");
          GetError(
            new Error("Falha ao emitir NF"),
            "addOrderLog",
            "OrderProvider",
            "Falha ao emitir NF",
            ErrorCodeType.NFCE
          );
        }
      } catch (error) {
        GetError(
          error,
          "onSucessPayment",
          "UseOrderProvider",
          "Falha ao emitir nota fiscal",
          ErrorCodeType.NFCE
        );
        addOrderLog(
          order,
          `Falha ao emitir nota fiscal - ${
            (error as any)?.response?.data?.message &&
            (error as any)?.response?.data?.message
          }`
        );
      }
    },
    [addOrderLog]
  );

  const printNfce = useCallback(
    async (nfce?: IEmitedNfce[]) => {
      try {
        if (nfce) {
          if (kiosk?.newPrinter) {
            await printInvoice(nfce);
          } else {
            await printService.printEletronicInvoice(nfce);
          }
        }
      } catch (error) {
        GetError(
          error,
          "printPaymentReceipt",
          "UseOrderProvider",
          "Falha ao imprimir NF",
          ErrorCodeType.NFCE
        );
      }
    },
    [kiosk, printInvoice]
  );

  const handleNfcePrintModal = useCallback(
    async (order: IOrder, kiosk: Kiosk) => {
      try {
        if (kiosk.generateEletronicInvoice) {
          await orderRepository.syncOrdersAsync(kiosk.localId);
          const nfce = await emitNfce(order);
          if (kiosk.mustPrintEletronicInvoice) {
            await printNfceRef.current?.open();
            showLoading();
            try {
              await printNfce(nfce);
            } catch (error) {
              GetError(
                error,
                "handleNfcePrintModal",
                "UseOrderProvider",
                `${(error as any)?.message ?? "Falha ao emitr NF"}`,
                ErrorCodeType.NFCE
              );
            } finally {
              hideLoading();
            }
          }
        }
      } catch (error) {
        GetError(
          error,
          "handleNfcePrintModal",
          "UseOrderProvider",
          `${(error as any)?.message ?? "Tempo expirado"}`,
          ErrorCodeType.NFCE
        );
      }
    },
    [
      emitNfce,
      hideLoading,
      orderRepository,
      printNfce,
      printNfceRef,
      showLoading,
    ]
  );

  const onSucessPayment = useCallback(
    async (
      orderWithCustomer: IOrder,
      transactionType: TransactionType,
      payment?: Payment,
      recharge?: "Recharge" | "Payment" | "WalletRecharge",
      pedidoPOS?: IPedidoPosDTO
    ) => {
      paymentReceipt.current = payment;
      _order.current = orderWithCustomer;
      if (kiosk) {
        const paidOrder = {
          ...orderWithCustomer,
          payment,
        };

        let password: IPasswordPainel | undefined;

        if (
          kiosk.mustEnableManagementPassword &&
          (!recharge || recharge === "Payment")
        ) {
          showLoading();
          password = await GetPasswordUseCase(
            orderRepository,
            kiosk.localId,
            sessaoIdPos ?? ""
          );
        }
        let syncResult: ISyncOrderResult | undefined;

        if (!pedidoPOS && (!recharge || recharge !== "WalletRecharge")) {
          syncResult = await syncOrder(
            { ...paidOrder, passwordPanelCode: password?.passwordForPrinting },
            transactionType,
            kiosk
          );
        } else {
          syncResult = {
            pedidoPOS,
          };
        }

        if (
          kiosk.mustEnableManagementPassword &&
          !password?.passwordForPrinting &&
          (!recharge || recharge === "Payment")
        ) {
          toastFullscreen(
            "Seu pedido foi gerado sem senha, Favor ir até o balcão",
            "warning"
          );
        }

        if (!recharge && syncResult?.pedidoPOS) {
          addLog("print", {
            message: "Start print",
            orderLog: { paidOrder, pedidoPos: syncResult.pedidoPOS },
          });
          PrintOrderUseCase(
            printService,
            paidOrder,
            syncResult.pedidoPOS,
            printOrder,
            printRemote,
            kiosk
          );
        }

        if (orderWithCustomer.totalValue > 0) {
          if (
            transactionType !== TransactionType.cashless &&
            transactionType !== TransactionType.pospaid &&
            transactionType !== TransactionType.pix //TODO: Remover ao criar comprovante pix
          ) {
            await handleReceiptPrintModal(kiosk, transactionType);
          }

          const isTransaction =
            transactionType === TransactionType.credit ||
            transactionType === TransactionType.debit ||
            transactionType === TransactionType.pix ||
            transactionType === TransactionType.voucher;

          const isRecharge = recharge === "Recharge";

          if (
            (!kiosk.generateEletronicInvoiceForRecharge && !isRecharge) ||
            (kiosk.generateEletronicInvoiceForRecharge && isTransaction)
          ) {
            if (recharge !== "WalletRecharge") {
              handleNfcePrintModal(paidOrder, kiosk);
            }
          }
        }

        hideLoading();
      }
      clearCart();
    },
    [
      kiosk,
      clearCart,
      hideLoading,
      showLoading,
      orderRepository,
      sessaoIdPos,
      syncOrder,
      toastFullscreen,
      addLog,
      printOrder,
      printRemote,
      handleReceiptPrintModal,
      handleNfcePrintModal,
    ]
  );

  const confirmPospaid = useCallback(
    async (
      orderWithCustomer: IOrder,
      transactionType: TransactionType,
      payment?: Payment,
      recharge?: "Recharge" | "Payment" | "WalletRecharge"
    ) => {
      paymentReceipt.current = payment;
      if (kiosk) {
        const paidOrder = {
          ...orderWithCustomer,
          payment,
        };

        let password: IPasswordPainel | undefined;

        if (
          kiosk.mustEnableManagementPassword &&
          (!recharge || recharge === "Payment")
        ) {
          showLoading();
          password = await GetPasswordUseCase(
            orderRepository,
            kiosk.localId,
            sessaoIdPos ?? ""
          );
        }

        const syncResult = await syncOrder(
          { ...paidOrder, passwordPanelCode: password?.passwordForPrinting },
          transactionType,
          kiosk
        );

        if (syncResult?.success) {
          await addOrderLog(
            { ...orderWithCustomer, paymentApproved: true },
            "Consumo aprovado",
            syncResult.pedidoPOS,
            {
              paymentMethod: transactionType,
            } as Payment
          );
          if (
            kiosk.mustEnableManagementPassword &&
            !password?.passwordForPrinting &&
            !recharge
          ) {
            toastFullscreen(
              "Seu pedido foi gerado sem senha, Favor ir até o balcão",
              "warning"
            );
          } else {
            !recharge &&
              toastFullscreen(
                kiosk?.messageOnPayment ?? "Pedido realizado com sucesso.",
                "success",
                {
                  onClose: () => {
                    replace("/");
                  },
                  time: 4000,
                }
              );
          }

          if (!recharge && syncResult?.pedidoPOS) {
            PrintOrderUseCase(
              printService,
              paidOrder,
              syncResult.pedidoPOS,
              printOrder,
              printRemote,
              kiosk
            );
          }

          if (orderWithCustomer.totalValue > 0) {
            if (
              transactionType !== TransactionType.cashless &&
              transactionType !== TransactionType.pospaid
            ) {
              await handleReceiptPrintModal(kiosk, transactionType);
            }
          }

          clearCart();
          hideLoading();
          return true;
        } else {
          await addOrderLog(
            orderWithCustomer,
            syncResult?.message ?? "Consumo recusado",
            syncResult?.pedidoPOS
          );
          toast(syncResult?.message ?? "Falha ao sincronizar pedido", "error");
          hideLoading();
          return false;
        }
      }
    },
    [
      kiosk,
      syncOrder,
      showLoading,
      sessaoIdPos,
      hideLoading,
      addOrderLog,
      clearCart,
      toastFullscreen,
      replace,
      printOrder,
      printRemote,
      handleReceiptPrintModal,
      toast,
      orderRepository,
    ]
  );

  const startPayment = useCallback(
    async (
      order: IOrder,
      recharge?: "Recharge" | "Payment" | "WalletRecharge",
      paymentType?: TransactionType
    ) => {
      setRechargePayment(!!recharge);
      try {
        if (order.orderItems.length === 0) {
          addOrderLog(order, `Carrinho vazio`);
          throw GetError(
            new Error("Carrinho vazio"),
            "startPayment",
            "UseOrderProvider",
            "Carrinho vazio",
            ErrorCodeType.GENERAL
          );
        }

        if (
          (kiosk?.customerFieldEnable &&
            !kiosk.customerFieldDisabled &&
            kiosk.customerField.length) ||
          kiosk?.generateEletronicInvoice ||
          kiosk?.prism
        ) {
          const customer = await customerRef.current?.open(kiosk, recharge);
          if (customer) {
            order = {
              ...order,
              customer: {
                ...order.customer,
                name: customer.name ?? order.customer?.name ?? "",
                phone: customer.phone ?? order.customer?.phone ?? "",
                document: customer.document ?? order.customer?.document ?? "",
                email: customer.email ?? order.customer?.email ?? "",
                clientIdentificator:
                  customer.clientIdentificator ??
                  order.customer?.clientIdentificator ??
                  "",
                prism: customer.prism ?? order.customer?.prism ?? "",
              },
            };
          }
        }

        await addOrderLog(order, "Pagamento Iniciado");
        let result: IPaymentResult | undefined = undefined;
        if (order.totalValue > 0) {
          const type =
            !kiosk?.paymentVoucher &&
            !kiosk?.paymentCredit &&
            !kiosk?.paymentDebit &&
            !kiosk?.paymentPix
              ? TransactionType.cashless
              : paymentType;
          result = await paymentProvider.current?.open(
            order,
            isSchoolData.current ? TransactionType.cashless : type,
            isSchoolData.current ? cardData ?? undefined : undefined
          );
        } else {
          result = {
            order,
            paymentType: TransactionType.money,
            payment: {
              success: true,
              amount: 0,
              paymentMethod: TransactionType.money,
              FinancialTransactionModel: {},
            },
          } as IPaymentResult;
        }
        if (!result) {
          addOrderLog(order, `Falha no pagamento`);
          throw GetError(
            new Error("Falha ao efetuar pagamento"),
            "startPayment",
            "UseOrderProvider",
            "Falha ao efetuar pagamento",
            ErrorCodeType.GENERAL
          );
        } else {
          if (result.paymentType !== TransactionType.pospaid) {
            !recharge &&
              toastFullscreen(
                kiosk?.messageOnPayment ?? "Pedido realizado com sucesso.",
                "success",
                {
                  onClose: () => {
                    replace("/");
                  },
                  time: 4000,
                }
              );
            await addOrderLog(
              { ...result.order, paymentApproved: true },
              "Pagamento realizado",
              result.pedidoPos,
              result.payment
            );
            await onSucessPayment(
              result.order,
              result.paymentType,
              result.payment,
              recharge,
              result.pedidoPos
            );
            return result;
          } else {
            const posPaidResult = await confirmPospaid(
              result.order,
              result.paymentType,
              result.payment,
              recharge
            );
            result.payment.success = !!posPaidResult;
            return result;
          }
        }
      } catch (_error) {
        const isArray = (_error as IError[]).length > 0;
        const iError = isArray ? (_error as IError[])[0] : (_error as IError);
        if (iError?.message && iError.message.includes("Pix")) {
          try {
            const cancelledPix: PixPendingTransaction = JSON.parse(
              iError.message
            );
            const paymentPix: PixApprovedTransaction = {
              ownerId: cancelledPix?.ownerId ?? "",
              paymentId: cancelledPix?.paymentId ?? "",
              qrCodeLink: cancelledPix?.qrCodeLink ?? "",
              status: cancelledPix?.status ?? "pending",
              value: cancelledPix?.value ?? order.totalValue,
              acquirerType: cancelledPix.acquirerType,
              createdAt: cancelledPix.createdAt,
              endToEndId: cancelledPix.endToEndId,
            };
            addOrderLog(
              {
                ...order,
                paymentPix,
              },
              cancelledPix?.message ?? `Falha no pagamento`,
              undefined,
              {
                FinancialTransactionModel: {
                  Status: StatusPedido.AguardandoPagamento,
                } as IFinancialTransactionModel,
              } as Payment
            );
          } catch (error) {
            addOrderLog(
              order,
              (error as IError)?.message ?? `Falha no pagamento`
            );
            toast((error as IError)?.message ?? "Falha no pagamento", "error");
          } finally {
            throw GetError(
              _error,
              "startPayment",
              "UseOrderProvider",
              "Falha ao efetuar pagamento²",
              ErrorCodeType.PAYMENT
            );
          }
        } else {
          let errMsg = "";
          try {
            const parsedError = JSON.parse(iError.message);
            errMsg = parsedError?.message;
          } catch (err) {
            errMsg = iError.message;
          }
          addOrderLog(order, errMsg ?? `Falha no pagamento`);
          toast(errMsg ?? "Falha no pagamento Pix", "error");
          throw GetError(
            _error,
            "startPayment",
            "UseOrderProvider",
            "Falha ao efetuar pagamento³",
            ErrorCodeType.PAYMENT
          );
        }
      }
    },
    [
      addOrderLog,
      cardData,
      confirmPospaid,
      customerRef,
      isSchoolData,
      kiosk,
      onSucessPayment,
      replace,
      toast,
      toastFullscreen,
    ]
  );

  useImperativeHandle(orderProviderRef, () => ({ startPayment }));

  return {
    startPayment,
    paymentProvider,
    nfcePrintRef,
    printerOrderComponentRef,
    rechargePayment,
  };
};

export default UseOrderProvider;
