import { IWalletTransactionResponse } from "modules/payment/domain/models/IWalletTransaction";
import React, { FC, memo } from "react";
import { hideCpf } from "utils/utils";
import { Kiosk } from "../../../domains/kiosk/aggregates/Kiosk";
import "./WalletReceiptLayout.css";

export interface WalletReceiptPrint extends IWalletTransactionResponse {
  orderDate: string;
  friendlyId: string;
}

interface WalletReceiptLayoutProps {
  walletReceipt: WalletReceiptPrint;
  kiosk: Kiosk;
}

export const WalletReceiptLayout: FC<WalletReceiptLayoutProps> = memo(
  ({ kiosk, walletReceipt }) => {
    return (
      <div id="WalletReceiptLayout">
        <div className="header">
          <span>VIA DO CLIENTE</span>
          <h5>COMPROVANTE DE PAGAMENTO WALLET</h5>
          <div>
            <div>
              <p>{new Date(walletReceipt.orderDate).toLocaleString("pt-BR")}</p>
              <p>{walletReceipt.friendlyId}</p>
            </div>
            <span>
              {`R$ ${walletReceipt.preOrder.totalValue
                .toFixed(2)
                .replace(".", ",")}`}
            </span>
          </div>
        </div>
        <div className="content">
          <p>{`CLIENTE: ${
            walletReceipt.meepUser.name.split(" ")[0]
          } - ${hideCpf(walletReceipt.meepUser.cpf)}`}</p>
          <p>{`TAG: ${walletReceipt.tag.replace(
            /([A-Za-z0-9]{2})(?=[A-Za-z0-9])/g,
            `$1-`
          )}`}</p>
          <p>{`TERMINAL: ${kiosk.kioskName}`}</p>
          <p style={{ fontSize: 12 }}>{`IDW: ${walletReceipt.walletId}`}</p>
          <p
            style={{ fontSize: 12 }}
          >{`IDO: ${walletReceipt.preOrder.preOrderId}`}</p>
        </div>
        <div className="footer">
          <span>SALDO ATUAL:</span>
          <span>{`R$ ${walletReceipt.balance
            .toFixed(2)
            .replace(".", ",")}`}</span>
        </div>
      </div>
    );
  }
);
