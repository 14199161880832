import { getGlobalAddLog } from "Infrastructure/repositories/appInsights/AppInsights";
import { IWalletRepository } from "modules/cashless/domain/interfaces/IWalletRepository";
import { IWalletRechargeModel, IWalletRechargeRequest } from "modules/payment/domain/models/IWalletTransaction";

const addLog = getGlobalAddLog();

export const WalletRechargeUseCase = async (
  service: IWalletRepository,
  rechargeModel: IWalletRechargeModel
) => {
  addLog &&
    addLog("WalletRechargeUseCase", rechargeModel);
  const payload: IWalletRechargeRequest = {
    LocalId: rechargeModel.localId,
    WalletId: rechargeModel.walletId,
    OrderId: rechargeModel.orderId,
    RechargeValue: rechargeModel.value,
    RechargeMotive: "Recarga Totem",
    MeepUserId: rechargeModel.userId,
    EquipamentId: rechargeModel.totemId,
    Payments: [
      {
        Amount: rechargeModel.value,
        Installments: 1,
        PaymentType: rechargeModel.paymentType,
        Success: true,
        TransactionResponse: rechargeModel.transaction,
        Discount: 0,
        HasDiscountInAmount: false,
      },
    ],
  };
  const wallet = await service.walletRecharge(payload);
  return wallet;
};
