import { IHttpRepository } from "Infrastructure/interfaces/IHttpRepository";
import { IWalletRepository } from "modules/cashless/domain/interfaces/IWalletRepository";
import {
  IWallet,
  IWalletExtract,
  IWalletExtractRequest,
} from "modules/cashless/domain/models/IWallet";
import {
  IWalletRechargeRequest,
  IWalletRechargeResponse,
  IWalletTransactionRequest,
  IWalletTransactionResponse,
} from "modules/payment/domain/models/IWalletTransaction";
import { HttpRepository } from "./_HttpRepository";

const httpRepository: IHttpRepository = HttpRepository();

export const WalletRepository = (): IWalletRepository => {
  const getWallet = async (LocalId: string, TAG: string): Promise<IWallet> => {
    const params = {
      TAG,
      LocalId,
    };
    return await httpRepository.getAsync<IWallet>(`WalletPOS/ByTag/`, {
      params,
    } as any);
  };

  const getWalletExtract = async (
    localId: string,
    tag: string
  ): Promise<IWalletExtract> => {
    const params: IWalletExtractRequest = {
      tag,
      localId,
    };
    return await httpRepository.getAsync<IWalletExtract>(`WalletPOS/Extract`, {
      params,
    });
  };

  const walletPayment = async (
    localId: string,
    orderId: string,
    tag: string,
    password: string,
    value: number,
    paymentId: string
  ): Promise<IWalletTransactionResponse> => {
    const payload: IWalletTransactionRequest = {
      LocalId: localId,
      OrderId: orderId,
      TAG: tag,
      Password: password,
      TotalValue: value,
      PaymentId: paymentId,
    };
    return await httpRepository.postAsync<
      IWalletTransactionRequest,
      IWalletTransactionResponse
    >("WalletPOS/PayWithWallet", payload);
  };

  const walletRecharge = async (
    payload: IWalletRechargeRequest
  ): Promise<IWalletRechargeResponse> => {
    return await httpRepository.postAsync<
      IWalletRechargeRequest,
      IWalletRechargeResponse
    >("WalletPOS/Recharge", payload);
  };

  return {
    getWallet,
    walletPayment,
    getWalletExtract,
    walletRecharge
  };
};
