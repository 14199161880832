import { getGlobalAddLog } from "Infrastructure/repositories/appInsights/AppInsights";
import { IWalletRepository } from "modules/cashless/domain/interfaces/IWalletRepository";

const addLog = getGlobalAddLog();

export const WalletPaymentUseCase = async (
  service: IWalletRepository,
  localId: string,
  orderId: string,
  tag: string,
  password: string,
  value: number,
  paymentId: string,
) => {
  addLog && addLog("WalletPaymentUseCase", { tag, orderId, value, paymentId });
  const wallet = await service.walletPayment(localId, orderId, tag, password, value, paymentId);
  return wallet;
};
