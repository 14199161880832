import { IWalletTransactionResponse } from "modules/payment/domain/models/IWalletTransaction";

export interface Payment {
	success: boolean;
	errorMessage: string | null;
	cardHash: string | null;
	amount: number;
	cardHolder: string;
	cardNumber: string;
	codigoTransacaoTEF: string | null;
	comprovanteTEF: string;
	paymentMethod: TransactionType;
	FinancialTransactionModel: IFinancialTransactionModel;
	walletReceipt?: IWalletTransactionResponse;
}

export interface IFinancialTransactionModel {
	CodigoEmpresa: string;
	BandeiraCartao: number;
	CodigoAdquirente: string;
	DataCriacao: string;
	FinalCartao: string;
	Parcelas: number;
	Status: number;
	TipoAdquirente: number;
	TipoPagamento: number;
	Valor: string;
	NumeroCupom: string;
	NSU?: string;
	CodigoAutorizacao?: string;
	CodigoBandeiraTEF?: string;
	TipoPagamentoTEF?: string;
	VersaoCefSharp?: string;
}

export enum TransactionType {
	credit = 1,
	debit = 2,
	money = 3,
	cashless = 4,
	pospaid = 5,
	misto = 6,
	voucher = 14,
	pix = 18,
  wallet = 22
}
