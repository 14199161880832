export interface IWallet {
  WalletId: string;
  Balance: number;
  UserName: string;
  CPF: string;
  Status: IWalletStatus;
}

export interface IExtractItemLocal { 
  localId: string;
  localName: string;
}

export enum TransactionType {
  Pending = 0,
  Recharge,
  Consumption,
  Refund,
  Transfer,
}

export interface IExtractItem {
  orderType: TransactionType;
  date: string;
  value: number;
  localOrder: IExtractItemLocal
}

export interface IMeepUser {
  name: string;
  cpf: string;
  id: string;
}

export interface IWalletExtract {
  balance: number;
  tag: string;
  meepUser: IMeepUser;
  walletId: string;
  extractWalletPOSItems: IExtractItem[]
}

export interface IWalletExtractRequest {
  localId: string;
  tag: string;
  walletId?: string;
}

export interface IWalletStatus {
  Id: WalletStatus;
  readonly Descricao: string;
}

export enum WalletStatus {
  Bloqueado = 0,
  Ativo,
  Cancelado,
  "Aguardando Segunda Via",
}

export enum PaymentTypeEnum {
  INDEFINIDO = -1,
  NAO_INFORMADO = 0,
  CREDITO = 1,
  DEBITO = 2,
  DINHEIRO = 3,
  CREDITOLOJA = 4,
  MEEPCOIN = 5,
  MISTO = 6,
  CHEQUE = 7,
  PENDENTE = 8,
  BOLETO = 9,
  REEMBOLSO = 10,
  CASHBACK = 11,
  PRODUCAO = 12,
  AJUSTE = 13,
  VOUCHER = 14,
  DELIVERY = 15,
  PERDA = 16,
  EM_ABERTO = 17,
  PIX = 18,
  FIDELITY_COIN = 19,
  PRE_RECARGA = 20,
  PERMUTA = 21,
  WALLET = 22,
  CUSTOMIZADO_1 = 101,
  CUSTOMIZADO_2 = 102,
  CUSTOMIZADO_3 = 103,
  CUSTOMIZADO_4 = 104,
  CUSTOMIZADO_5 = 105,
  CUSTOMIZADO_6 = 106,
  CUSTOMIZADO_7 = 107,
  CUSTOMIZADO_8 = 108,
  CUSTOMIZADO_9 = 109,
  CUSTOMIZADO_10 = 110,
  CUSTOMIZADO_11 = 111,
  CUSTOMIZADO_12 = 112,
  CUSTOMIZADO_13 = 113,
  CUSTOMIZADO_14 = 114,
  CUSTOMIZADO_15 = 115,
  CUSTOMIZADO_16 = 116,
  CUSTOMIZADO_17 = 117,
  CUSTOMIZADO_18 = 118,
  CUSTOMIZADO_19 = 119,
  CUSTOMIZADO_20 = 120,
  CUSTOMIZADO_21 = 121,
  CUSTOMIZADO_22 = 122,
  CUSTOMIZADO_23 = 123,
  CUSTOMIZADO_24 = 124,
  CUSTOMIZADO_25 = 125,
  CUSTOMIZADO_26 = 126,
  CUSTOMIZADO_27 = 127,
  CUSTOMIZADO_28 = 128,
  CUSTOMIZADO_29 = 129,
  CUSTOMIZADO_30 = 130
}
